<template>
    <el-form ref="form" :model="form" :rules="formRules" :class="{disabled:formDisabled}" :disabled="formDisabled" label-width="120px">
      <div v-if="fieldShow.bidCode!==false" class="col col4">
        <el-form-item label="招标编号" prop="bidCode">
            <el-input v-model="form.bidCode"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.itemName!==false" class="col col4">
        <el-form-item label="项目名称" prop="itemName">
            <el-input v-model="form.itemName"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.createUserName!==false" class="col col4">
        <el-form-item label="发布人" prop="createUserName">
            <el-input v-model="form.createUserName"></el-input>
        </el-form-item>
      </div>
      <div v-show="fieldShow.businessScope!==false" class="col col4">
        <el-form-item label="经营范围" prop="businessScope">
          <div class="flex-table">
            <div class="flex-table-row">
              <el-form-item>
                <el-select @change="ScopeChange" v-model="form.businessScope">
                    <el-option :key="index" v-for="(itme,index) in businessScopeList" :label="itme.companyDesc" :value="itme.businessScope"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flex-table-row">
              <el-form-item prop="companyCode">
                <el-select @change="companyChange" v-model="form.companyCode">
                    <el-option :key="index" v-for="(itme,index) in companyCode" :label="itme.companyCode" :value="itme.companyCode"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form-item>
      </div>
      <div v-if="fieldShow.companyName!==false" class="col col4">
        <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="form.companyName"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.bidMinPrice!==false" class="col col4">
        <el-form-item label="招标控制价" prop="bidMinPrice">
            <el-input v-model="form.bidMinPrice"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.bidMinDesc!==false" class="col col4">
        <el-form-item label="标底说明" prop="bidMinDesc">
            <el-input v-model="form.bidMinDesc"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.bidEndDate!==false" class="col col4">
        <el-form-item label="应标截止日期" prop="bidEndDate">
            <el-date-picker v-model="bidEndDate" type="datetime" placeholder="应标截止日期"></el-date-picker>
        </el-form-item>
      </div>
      <div v-if="fieldShow.bidStartDate!==false" class="col col4">
        <el-form-item label="开标日期" prop="bidStartDate">
            <el-date-picker v-model="bidStartDate" type="datetime" placeholder="开标日期"></el-date-picker>
        </el-form-item>
      </div>
      <div v-if="fieldShow.paymentDays!==false" class="col col4">
        <el-form-item label="账期" prop="paymentDays">
            <el-autocomplete v-model="form.paymentDays" :fetch-suggestions="querySearch" placeholder="请输入内容"></el-autocomplete>
        </el-form-item>
      </div>
      <div v-if="fieldShow.titleList!==false" class="col col4">
        <el-form-item label="报价指标" prop="titleList">
            <el-select v-model="form.titleList" value-key="detailsKey" multiple>
                  <el-option :key="index" v-for="(item,index) in quotationData" :label="item.detailsValue" :value="item"></el-option>
              </el-select>
        </el-form-item>
      </div>
      <div v-if="fieldShow.bidFileList!==false" class="col col4">
        <el-form-item label="招标文件" :class="{isreadonly:formDisabled}" prop="bidFileList">
            <el-upload
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :on-exceed="handleExceed"
                :file-list="form.bidFileList"
            >
                <el-button v-if="!formDisabled" size="small" type="primary" plain>点击上传</el-button>
            </el-upload>
        </el-form-item>
      </div>
      <div v-if="fieldShow.bidMaterialMap!==false" class="col col8">
        <el-form-item label="明细项" v-if="!formDisabled" prop="bidMaterialMap">
            <el-upload
                v-if="!formDisabled"
                :action="materialURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="materialSuccess"
                :show-file-list="false"
                multiple
                :on-exceed="handleExceed"
            >
                <el-button size="small" type="primary" plain>点击上传</el-button>
            </el-upload>
          
        </el-form-item>
        <div class="headerH2">明细项</div>
        <div class="tableList" :class="{isreadonly:formDisabled}">
            <div class="table">
              <el-table border class="materTable" v-if="form.bidMaterialMap&&form.bidMaterialMap.title&&form.bidMaterialMap.title.length>0" :data="form.bidMaterialMap.data" style="width: 100%">
                  <template :key="item" v-for="(item) in form.bidMaterialMap.title">
                      <el-table-column v-if="item!=='lineNumber'"  :prop="item" :label="item"></el-table-column>
                  </template>
              </el-table>
            </div>
          </div>
      </div>
    </el-form>
</template>

<script>
import { baseHost } from '@/assets/js/PublicData.js'
// import { MIME } from '@/assets/js/mime.js'
import { baseURL, request } from '@/assets/js/http.js'
import { ref } from 'vue'
import dayjs from 'dayjs'

export default {
  name: 'BidInfo',
  props: ['formData', 'dataFormat'],
  data: function () {
    return {
      form: this.formData,
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      materialURL: baseHost.SupplierRegHost + '/api/bid/upload/material',
      tokenHeader: { token: localStorage.getItem('token') },
      restaurants: ref([]),
      businessScopeList: [],
      companyCode: [],
      quotationData: [],
      formRules: this.dataFormat ? this.dataFormat.rules : {},
      formDisabled: false,
      fieldShow: {},
      bidEndDate: this.formData.bidEndDate,
      bidStartDate: this.formData.bidStartDate
    }
  },
  watch: {
    form: {
      handler: function () {
        this.$emit('dataAction', this.form)
      },
      deep: true
    },
    formData: function () {
      this.form = this.formData
      this.bidEndDate = this.formData.bidEndDate
      this.bidStartDate = this.formData.bidStartDate
      if (this.form.businessScope && this.form.businessScope !== '') {
        request('/api/sap/getCompanyCodeName/' + this.form.businessScope, 'get').then((response) => {
          this.companyCode = response
        })
      }
    },
    bidEndDate: function (val) {
      this.dateFormate(this.formData, 'bidEndDate', val)
    },
    bidStartDate: function (val) {
      this.dateFormate(this.formData, 'bidStartDate', val)
    }
  },
  mounted: function () {
    if (this.dataFormat) {
      if (this.dataFormat.formDisabled) {
        this.formDisabled = true
      }
      if (this.dataFormat.fieldShow) {
        this.fieldShow = this.dataFormat.fieldShow
      }
    }
    this.restaurants.value = [{ value: '30天' }, { value: '45天' }, { value: '60天' }, { value: '90天' }, { value: '按合同文件执行' }]
    request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
      this.businessScopeList = response
    })
    if (this.form.businessScope && this.form.businessScope !== '') {
      request('/api/sap/getCompanyCodeName/' + this.form.businessScope, 'get').then((response) => {
        this.companyCode = response
      })
    }
    request('/api/system/dictDetails/getByGroupCode?groupCode=BID_STANDARD', 'get').then((response) => {
      this.quotationData = response
    })
  },
  methods: {
    companyChange: function (data) {
      for (var item of this.companyCode) {
        if (item.companyCode === data) {
          this.form.companyName = item.companyName
        }
      }
    },
    dateFormate: function (obj, field, data) {
      obj[field] = dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    ScopeChange: function () {
      this.form.companyCode = ''
      if (this.form.businessScope !== '') {
        request('/api/sap/getCompanyCodeName/' + this.form.businessScope, 'get').then((response) => {
          this.companyCode = response
        })
      }
    },
    materialSuccess (response, file, fileList) {
      if (response.code === '200') {
        this.form.bidMaterialMap = response.data
      }
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.bidFileList.length; i++) {
        if (this.form.bidFileList[i].id === file.id) {
          this.form.bidFileList.splice(i, 1)
        }
      }
    },
    querySearch (queryString, cb) {
      var results = queryString ? this.restaurants.value.filter(this.createFilter(queryString)) : this.restaurants.value
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handlePreview (file) {
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id + '?type=1')
      /* exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        }) */
    },
    handleExceed (files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.bidFileList.push(element)
        })
      }
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        // if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
        eleLink.download = fileName
        // }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.headerH2{
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  color: #666;
}
.headerH2:before {
  content: " ";
  display: inline-block;
  width: 4px;
  height: 20px;
  vertical-align: text-top;
  margin-right: 5px;
}
.disabled {
  .col{
    margin: 3px 0 !important;
  }
}
.isreadonly{
  padding: 0;
  .table{
    .el-table{
      margin-top: 10px !important;
    }
  }
  :deep(.el-form-item__content){
    .el-upload--text{
      display: none;
    }
    .el-upload-list__item:first-child {
      margin-top: 5px;
    }
  }
}
.tableList{
  padding: 0;
}
</style>
